.root {
    position: relative;
    max-height: 400px;
    height: 100%;
}

.root::after {
    position: absolute;
    content: '';
    display: block;
    height: 12px;
    width: 65%;
    left: 0;
    bottom: -5px;
    background-color: var(--blue);
}

.wrapper {
    position: absolute;
    width: 60%;
    height: 115px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 5;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 50.83%, rgba(255, 255, 255, 0) 100%);
    text-align: center;
}

.title {
    font-size: var(--font-size-XXXL);
    text-transform: uppercase;
}

.backgroundImg {
    z-index: -1;
    width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    .root {
        max-height: 534px;
    }

    .root::after {
        height: 9px;
        bottom: -1px;
        width: 85%;
    }

    .title {
        font-size: var(--font-size-L);
    }

    .wrapper {
        width: 100%;
    }

    .wrapper span {
        font-size: var(--font-size-S);
    }
}