.root {
    width: 100%;
    min-height: 512px;
    display: flex;
    justify-content: center;
    background: linear-gradient(0deg, rgba(42, 54, 79, 0.8), rgba(42, 54, 79, 0.8)),  url('../../assets/images/galleryBackround.jpg');
    background-size: cover;
}

.wrapper {
    width: 100%;
    max-width: var(--max-width);
    display: grid;
    grid-template-columns: 50% 50%;
    align-self: center;
    padding: 0 120px;
}

.info {
    align-self: center;
    color: var(--white);
    padding-right: 120px;
    display: grid;
    row-gap: 25px;
}

.notification {
    text-decoration: underline;
}

@media (max-width: 1250px) {
    .wrapper {
        padding: 0 60px;
    }

    .info {
        padding-right: 60px;
    }
}

@media (max-width: 768px) {
    .root {
        background: none;
        min-height: auto;
    }

    .wrapper {
        grid-template-columns: 100%;
        padding: 0 30px;
    }

    .info {
        row-gap: 15px;
    }

    .info span {
        font-size: var(--font-size-S);
        color: var(--gray-600);
    }

    .info h2 {
        color: var(--text-color);
        font-size: var(--font-size-L);
    }

    .info p {
        display: none;
    }

    .notification {
        display: none;
    }
}

