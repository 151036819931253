@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap);
.offerCard_root__3QgY- {
    width: 100%;
    max-width: 470px;
    border: 1px solid var(--gray-400);
    display: flex;
    flex-direction: column;
    padding: 55px 58px 67px;
    position: relative;
}

.offerCard_label__34mEi {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    height: 43px;
    min-width: 210px;
    background-color: var(--dark-blue);
    color: var(--white);
    position: absolute;
    top: -22px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    text-align: center;
}

.offerCard_number__2HxZ4 {
    position: absolute;
    top: 22px;
    left: -36px;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background-color: var(--gray-300);
    color: var(--grenade);
    display: grid;
    place-items: center;
    font-size: var(--font-size-XXXXL);
    font-weight: var(--weight-bold);
}

.offerCard_pretitle__2_RP8 {
    text-align: center;
    color: var(--gray-600);
}

.offerCard_title__18onY {
    margin: 27px 0 34px;
    text-align: center;
    font-weight: var(--weight-semi-bold);
}

.offerCard_description__2UJFz {
    margin: 38px 0;
}

.offerCard_link__1I2Sw {
    font-size: var(--font-size-S);
    display: flex;
    align-items: center;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.offerCard_link__1I2Sw svg {
    margin-left: 11px;
}

.offerCard_link__1I2Sw::after {
    display: block;
    content: '';
    position: absolute;
    bottom: -3px;
    width: 100%;
    height: 1px;
    background-color: #252A2F;
}

@media (max-width: 768px) {
    .offerCard_root__3QgY- {
        padding: 45px 35px 25px;
    }

    .offerCard_label__34mEi {
        font-size: var(--font-size-XS);
        max-width: 120px;
        max-height: 30px;
        min-width: auto;
        padding: 0 10px;
    }

    .offerCard_number__2HxZ4 {
        display: none;
    }

    .offerCard_pretitle__2_RP8 {
        font-size: var(--font-size-M);
    }

    .offerCard_title__18onY {
        margin: 8px 0 10px;
    }

    .offerCard_description__2UJFz {
        font-size: var(--font-size-M);
        margin: 11px 0 19px;
    }

    .offerCard_link__1I2Sw {
        font-size: var(--font-size-S);
    }
}
.button_root__3ZVpF {
    white-space: nowrap;
    pointer-events: auto;
    cursor: pointer;
    min-width: 156px;
    height: 44px;
    max-width: 100%;
    padding: 0 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    font-weight: var(--weight-semi-bold);
    transition-duration: 100ms;
    transition-property: background-color, color;
    transition-timing-function: var(--anim-standard);
}

.button_root_normalPriority__1gAsS {

    background-color: var(--dark-blue);
    color: var(--gray-200);
    box-shadow: 0px 2px 4px rgba(42, 54, 79, 0.5);
}

.button_root_normalPriority__1gAsS:hover {
    background-color: var(--gray-300);
    color: var(--text-color);
}

.button_root_whitePriority__218Ng {

    background-color: var(--gray-300);
    color: var(--text-color);
    box-shadow: 0px 1px 4px #A7ACB2;
}

.button_root_whitePriority__218Ng:hover {
    background-color: var(--dark-blue);
    color: var(--gray-200);
    box-shadow: 0px 2px 4px rgba(42, 54, 79, 0.5);
}
.aboutUsSection_root__B8f7b {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
}

.aboutUsSection_wrapper__RFa8o {
    width: 100%;
    max-width: var(--max-width);
    display: flex;
    flex-direction: column;
    position: relative;
}

.aboutUsSection_wrapper__RFa8o::before {
    position: absolute;
    content: '';
    display: block;
    top: 100px;
    bottom: 0;
    left: 0;
    width: 6px;
    background-color: var(--blue);
}

.aboutUsSection_offerTitle__3vlYZ {
    text-align: center;
    margin-bottom: 70px;
}

.aboutUsSection_offer__RR7dL {
    display: flex;
    justify-content: center;
}

.aboutUsSection_offer__RR7dL div:first-child {
    margin-right: 75px;
}

.aboutUsSection_aboutUs__27HCT {
    display: grid;
    grid-template-columns: 1fr 2fr;
    place-items: center;
    padding-left: 120px;
}

.aboutUsSection_aboutUsInfo__19kds {
    display: grid;
    grid-row-gap: 30px;
    row-gap: 30px;
}

.aboutUsSection_pretitle__2QGBi {
    color: var(--gray-600);
}

.aboutUsSection_button__3BMSY {
    max-width: 183px;
}

.aboutUsSection_mockup__3ct-5 {
    max-width: 911px;
    width: 100%;
    height: auto;
}

@media (max-width: 1440px) {
    .aboutUsSection_wrapper__RFa8o::before {
        display: none;
    }

    .aboutUsSection_aboutUs__27HCT {
        padding-left: 50px;
        padding-right: 20px;
    }
}

@media (max-width: 1200px) {
    .aboutUsSection_root__B8f7b {
        margin-top: 60px;
    }

    .aboutUsSection_aboutUs__27HCT {
        margin-bottom: 60px;
    }
}

@media (max-width: 1100px) {
    .aboutUsSection_offer__RR7dL {
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
    }

    .aboutUsSection_offer__RR7dL div:first-child {
        margin-right: 0;
        margin-bottom: 60px;
    }
}

@media (max-width: 1000px) {
    .aboutUsSection_aboutUs__27HCT {
        grid-template-columns: 1fr 1fr;
    }

    .aboutUsSection_aboutUsInfo__19kds {
        margin-right: 30px;
    }
}

@media (max-width: 768px) {
    .aboutUsSection_offerTitle__3vlYZ {
        text-align: left;
        padding: 0 40px;
        margin-bottom: 50px;
    }

    .aboutUsSection_offer__RR7dL {
        margin: 0 42px;
    }

    .aboutUsSection_aboutUsMockup__2m0EI {
        display: none;
    }

    .aboutUsSection_aboutUs__27HCT {
        grid-template-columns: 1fr;
        padding: 0 40px;
    }

    .aboutUsSection_aboutUsInfo__19kds {
        margin-right: 0;
        margin-bottom: 30px;
    }

    .aboutUsSection_pretitle__2QGBi {
        font-size: var(--font-size-S);
    }

    .aboutUsSection_title__3mojU {
        font-size: var(--font-size-L);
    }

    .aboutUsSection_description__1d4rG {
        font-size: var(--font-size-M);
    }

    .aboutUsSection_button__3BMSY {
        font-size: var(--font-size-S);
    }
}
.loaderIndicator_root__3zkh- {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
}

.loaderIndicator_rootFullScreen__2IEk1 {

    width: 100vw;
    height: 100vh;
    background: var(--white);
    z-index: 1000;
    position: fixed;
    left: 0;
    top: 0;
}
.frame_root__2EImT {
    width: 100%;
    height: 627px;
    background-color: var(--gray-200);
    box-shadow: 0px 4px 4px rgba(101, 106, 111, 0.25);
    display: flex;
    align-items: center;
    padding-left: 15%;
    position: relative;
}

.frame_root__2EImT::before {
    position: absolute;
    display: block;
    content: '';
    width: 40%;
    height: 6px;
    top: -6px;
    left: 0;
    background-color: var(--blue);
}

.frame_infoSection__2d1E7 {
    max-width: 420px;
}

.frame_preTitle__2Sd7n {
    color: var(--gray-600);
    font-size: var(--font-size-L);
}

.frame_title__2HymA {
    font-size: var(--font-size-XXL);
    margin: 20px 0;
}

.frame_paragraph__1sPa- {
    font-weight: var(--weight-bold);
}

.frame_list__1a-Me {
    margin: 0;
    padding-left: 29px;
}

.frame_stepsSection__3L8hT {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 40px 0 40px;
}

.frame_dot__F38Yw {
    display: block;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: radial-gradient(38.24% 38.24% at 50% 50%, #637292 0%, #485878 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-weight: var(--weight-bold);
    font-size: var(--font-size-XXXXL);
    margin: 20px 0;
    position: relative;
}

.frame_dot__F38Yw:nth-child(2)::after,
.frame_dot__F38Yw:nth-child(3)::after,
.frame_dot__F38Yw:nth-child(4)::after {
    position: absolute;
    display: block;
    content: '';
    width: 72px;
    height: 72px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background: transparent;
    border: 1px solid var(--dark-blue);
    border-radius: 50%;
}

.frame_dot__F38Yw:nth-child(1)::before,
.frame_dot__F38Yw:nth-child(2)::before,
.frame_dot__F38Yw:nth-child(3)::before {
    position: absolute;
    display: block;
    width: 1px;
    height: 33px;
    content: '';
    bottom: -36px;
    left: 50%;
    background-color: var(--dark-blue);
}

.frame_dot__F38Yw:nth-child(1)::before {
    height: 37px;
}

@media (max-width: 1100px) {
    .frame_root__2EImT {
        padding-left: 10%;
    }

    .frame_stepsSection__3L8hT {
        padding: 50px 30px 0 20px;
    }
}

@media (max-width: 900px) {
    .frame_root__2EImT {
        height: 800px;
    }
}

@media (max-width: 768px) {
    .frame_root__2EImT {
        box-shadow: none;
        background-color: transparent;
        background-image: url(/static/media/technologyBackground.8ea062db.png);
        background-repeat: no-repeat;
        background-size: cover;
        justify-content: center;
        position: relative;
        color: var(--white);
        padding: 0 34px;
        height: 520px; 
    }

    .frame_root__2EImT::before {
        display: none;
    }

    .frame_root__2EImT::after {
        position: absolute;
        display: block;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(42, 54, 79, 0.8);
    }

    .frame_stepsSection__3L8hT {
        display: none;
    }

    .frame_infoSection__2d1E7 {
        z-index: 2;
        font-size: var(--font-size-M);
    }

    .frame_title__2HymA {
        font-size: var(--font-size-XL);
    }

    .frame_preTitle__2Sd7n {
        color: var(--white);
    }
}

@media (max-width: 500px) {
    .frame_title__2HymA {
        font-size: var(--font-size-L);
    }

    .frame_preTitle__2Sd7n {
        font-size: var(--font-size-S);
    }
}
.technologySection_root__3tqzi {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 200px;
    position: relative;
}

.technologySection_root__3tqzi::before {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    height: 387px;
    width: 40%;
    background-color: var(--dark-blue);
}

.technologySection_wrapper__iciAB {
    width: 100%;
    max-width: var(--max-width);
    display: flex;
}

.technologySection_imageSection__9W2Zn {
    height: 387px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 50%;
}

.technologySection_infoSection__EO8t- {
    width: 50%;
}

@media (max-width: 768px) {
    .technologySection_imageSection__9W2Zn {
        display: none;
    }

    .technologySection_infoSection__EO8t- {
        width: 100%;
    }

    .technologySection_root__3tqzi {
        margin-top: 110px;
    }
}
.homepageBanner_root__1AIP_ {
    display: grid;
    place-items: center;
    position: relative;
}

.homepageBanner_root__1AIP_::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 70%;
    height: 20px;
    background-color: var(--blue);
}

.homepageBanner_background__2tdSu {
    display: grid;
    place-items: center;
    height: 100%;
    width: 100%;
    max-height: 780px;
}

.homepageBanner_titleWrapper__3t3cI {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 55%;
    height: 100%;
    max-height: 214px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.74) 28.14%, #FFFFFF 49.66%, rgba(255, 255, 255, 0.73) 73.92%, rgba(255, 255, 255, 0) 100%);
    box-shadow: 0px 2px 2px rgba(53, 67, 93, 0.25);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 36px;
    text-align: center;
}

.homepageBanner_title__3Ot6k {
    text-transform: uppercase;
    font-size: var(--font-size-XXXL);
    margin-bottom: 5px;
}

.homepageBanner_button__2l1F4 {
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateY(50%) translateX(-50%);
            transform: translateY(50%) translateX(-50%);
}

@media (max-width: 1100px) {
    .homepageBanner_titleWrapper__3t3cI {
        width: 75%;
    }
}

@media (max-width: 768px) {
    .homepageBanner_root__1AIP_::after {
        height: 9px;
        bottom: -9px;
        width: 85%;
    }

    .homepageBanner_titleWrapper__3t3cI {
        max-height: 116px;
        padding-top: 14px;
        width: 100%;
    }

    .homepageBanner_title__3Ot6k {
        font-size: var(--font-size-L);
    }

    .homepageBanner_paragraph__3Hg0_ {
        font-size: var(--font-size-S);
    }

    .homepageBanner_button__2l1F4 {
        width: 96px;
        height: 31px;
        font-size: var(--font-size-S);
        min-width: auto;
        bottom: -5px;
    }
}


.pageTitleSection_root__X2AXh {
    position: relative;
    max-height: 400px;
    height: 100%;
}

.pageTitleSection_root__X2AXh::after {
    position: absolute;
    content: '';
    display: block;
    height: 12px;
    width: 65%;
    left: 0;
    bottom: -5px;
    background-color: var(--blue);
}

.pageTitleSection_wrapper__1GExC {
    position: absolute;
    width: 60%;
    height: 115px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 5;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 50.83%, rgba(255, 255, 255, 0) 100%);
    text-align: center;
}

.pageTitleSection_title__kq9wE {
    font-size: var(--font-size-XXXL);
    text-transform: uppercase;
}

.pageTitleSection_backgroundImg__1sksX {
    z-index: -1;
    width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    .pageTitleSection_root__X2AXh {
        max-height: 534px;
    }

    .pageTitleSection_root__X2AXh::after {
        height: 9px;
        bottom: -1px;
        width: 85%;
    }

    .pageTitleSection_title__kq9wE {
        font-size: var(--font-size-L);
    }

    .pageTitleSection_wrapper__1GExC {
        width: 100%;
    }

    .pageTitleSection_wrapper__1GExC span {
        font-size: var(--font-size-S);
    }
}
.accordion_root__1UvrL {
    width: 100%;
    max-width: 580px;
}

.accordion_heading__2eSvC {
    display: flex;
    align-items: center;
    height: 48px;
    width: 100%;
    cursor: pointer;
    border-bottom: 1px solid var(--text-color);
}

.accordion_button__u-PjH {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0 22px
}

.accordion_button__u-PjH[aria-expanded='true'] {
    background-color: var(--gray-200);
}

.accordion_button__u-PjH svg {
    margin-right: 15px;
}

.accordion_panel__1f4RA {
    background-color: var(--gray-200);
    padding: 30px 22px;
    width: 100%;
    box-sizing: border-box;
}

.accordion_content__3f5lS {
    margin: 0;
    -webkit-animation: accordion_anim__3cm8Z 200ms var(--anim-bounce);
            animation: accordion_anim__3cm8Z 200ms var(--anim-bounce);
}

[hidden] {
    display: none;
}

@-webkit-keyframes accordion_anim__3cm8Z {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
                transform: translateY(-100%);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@keyframes accordion_anim__3cm8Z {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
                transform: translateY(-100%);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .accordion_heading__2eSvC {
        font-size: var(--font-size-S);
        text-align: left;
    }
}
.aboutUsPage_root__1jLqu {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.aboutUsPage_wrapper__21Oyw {
    width: 100%;
    max-width: var(--max-width);
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-left: 120px;
}

.aboutUsPage_wrapper__21Oyw::before {
    display: block;
    content: '';
    position: absolute;
    left: 0px;
    top: 0;
    bottom: 120px;
    width: 6px;
    background-color: var(--blue);
}

.aboutUsPage_info__2Iu59 {
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
    margin-bottom: 120px;
}

.aboutUsPage_pretitle__24oXM {
    color: var(--gray-600);
    margin-bottom: 24px;
}

.aboutUsPage_title__3yugZ {
    margin-bottom: 24px;
}

.aboutUsPage_description__2rNXS {
    margin-bottom: 50px;
    padding: 0 80px;
}

.aboutUsPage_pretitle__24oXM,
.aboutUsPage_title__3yugZ,
.aboutUsPage_description__2rNXS {
    padding-right: 120px;
}

.aboutUsPage_timelineWrapper__151_c {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin-bottom: 110px;
    margin-right: 30px;
}

.aboutUsPage_timelineItem__1ERxu {
    padding: 0 10px;
}

.aboutUsPage_container__17yvd {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: left;
}

.aboutUsPage_accordion__2ZLqC {
    display: flex;
    flex-direction: column;
}

.aboutUsPage_accordionPretitle__2L4_b {
}

.aboutUsPage_accordionTitle__1BFjw {
}

.aboutUsPage_accordionDescription__2gy1J {
    margin-bottom: 20px;
}

.aboutUsPage_image__2sjsq {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

.aboutUsPage_image__2sjsq img {
    width: 100%;
    max-width: 515px;
    height: auto;
}

.aboutUsPage_sentence__FFDKh {
    position: absolute;
    width: 420px;
    height: 100px;
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 60px;
    left: 0;
    box-shadow: 0px 2px 4px rgba(101, 106, 111, 0.3);
    border-radius: 1px 8px 1px;
}

.aboutUsPage_quot__2cySq {
    font-style: italic;
    position: absolute;
    top: 0px;
    left: 10px;
    font-weight: var(--weight-bold);
    color: rgba(72, 88, 120, 0.4);
    font-size: 60px;
}

.aboutUsPage_advantagesWrapper__SHdLS {

    position: relative;
    padding-left: 0;
    margin-bottom: 80px;
    padding: 0;
    padding-left: 0 !important;
}

.aboutUsPage_advantagesWrapper__SHdLS::after {
    display: block;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: -50px;
    width: 6px;
    background-color: var(--blue);
}

.aboutUsPage_advantagesWrapper__SHdLS::before {
    display: none;
}

.aboutUsPage_advantages__1tgRN {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0 105px;
}

.aboutUsPage_advantages__1tgRN span {
    margin-bottom: 20px;
    color: var(--gray-600);
}

.aboutUsPage_advantages__1tgRN h2 {
    margin-bottom: 100px;
}

.aboutUsPage_items__3wK9C {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: left;
    grid-row-gap: 70px;
    row-gap: 70px;
}

.aboutUsPage_item__1LQBo {
    padding: 0 48px;
    position: relative;
}

.aboutUsPage_item__1LQBo::before {
    display: block;
    content: '';
    position: absolute;
    top: 6px;
    left: 0;
    background-color: var(--blue);
    width: 16px;
    height: 16px;
}

@media (max-width: 1460px) {
    .aboutUsPage_wrapper__21Oyw::before {
        left: 20px;
    }

    .aboutUsPage_title__3yugZ, 
    .aboutUsPage_pretitle__24oXM {
        padding-right: 0;
    }

    .aboutUsPage_description__2rNXS {
        padding: 0 30px;
    }

    .aboutUsPage_advantagesWrapper__SHdLS::after {
        display: none;
    }
}

@media (max-width: 1250px) {
    .aboutUsPage_wrapper__21Oyw {
        padding-left: 60px;
    }

    .aboutUsPage_sentence__FFDKh {
        left: 50px;
    }
}

@media (max-width: 1200px) {
    .aboutUsPage_container__17yvd {
        grid-column-gap: 25px;
        -webkit-column-gap: 25px;
                column-gap: 25px;
    }
}

@media (max-width: 1000px) {
    .aboutUsPage_container__17yvd {
        grid-template-columns: 1fr;
        text-align: center;
    }

    .aboutUsPage_sentence__FFDKh {
        display: none;
    }

    .aboutUsPage_image__2sjsq {
        justify-content: center;
        margin-top: 100px;
    }

    .aboutUsPage_accordion__2ZLqC {
        align-items: center;
    }

    .aboutUsPage_accordion__2ZLqC > div {
        margin-top: 60px;
    }

    .aboutUsPage_accordionDescription__2gy1J {
        padding: 0 30px;
    }

    .aboutUsPage_advantages__1tgRN {
        padding: 0 50px;
    }
}

@media (max-width: 768px) {
    .aboutUsPage_wrapper__21Oyw {
        margin-top: 100px;
        padding: 0 30px;
    }

    .aboutUsPage_wrapper__21Oyw::before {
        display: none;
    }

    .aboutUsPage_info__2Iu59 {
        margin-bottom: 100px;
    }

    .aboutUsPage_info__2Iu59 span,
    .aboutUsPage_info__2Iu59 p {
        font-size: var(--font-size-S);
    }

    .aboutUsPage_info__2Iu59 h2 {
        font-size: var(--font-size-L);
    }

    .aboutUsPage_image__2sjsq {
        display: none;
    }

    .aboutUsPage_description__2rNXS {
        margin-bottom: 50px;
    }

    .aboutUsPage_timelineWrapper__151_c {
        grid-template-columns: 1fr;
        margin-right: 0;
        justify-items: center;
    }

    .aboutUsPage_timelineItem__1ERxu {
        margin-bottom: 20px;
        width: 75%;
    }

    .aboutUsPage_timelineItem__1ERxu:last-child {
        margin-bottom: 0;
    }

    .aboutUsPage_accordion__2ZLqC > div {
        margin-top: 25px;
    }

    .aboutUsPage_pretitle__24oXM,
    .aboutUsPage_title__3yugZ {
        margin-bottom: 15px;
    }

    .aboutUsPage_accordionDescription__2gy1J,
    .aboutUsPage_description__2rNXS {
        padding: 0;
    }

    .aboutUsPage_advantagesWrapper__SHdLS {
        padding: 0;
        margin-bottom: 0;
    }

    .aboutUsPage_advantages__1tgRN {
        padding: 0;
    }

    .aboutUsPage_advantages__1tgRN h2 {
        font-size: var(--font-size-L);
        margin-bottom: 60px;
    }

    .aboutUsPage_advantages__1tgRN span {
        font-size: var(--font-size-S);
        margin-bottom: 14px;
    }

    .aboutUsPage_items__3wK9C h3 {
        font-size: var(--font-size-L);
        position: relative;
        margin-bottom: 14px;
    }

    .aboutUsPage_items__3wK9C p {
        font-size: var(--font-size-S);
    }

    .aboutUsPage_items__3wK9C {
        grid-template-columns: 1fr;
        width: 100%;
        padding: 0 75px;
    }

    .aboutUsPage_item__1LQBo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0;
    }

    .aboutUsPage_item__1LQBo::before {
        display: none;
    }

    .aboutUsPage_item__1LQBo h3::before {
        display: block;
        position: absolute;
        top: 50%;
        left: -16px;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        content: '';
        width: 8px;
        height: 8px;
        background-color: var(--blue);
    }
}

@media (max-width: 500px) {
    .aboutUsPage_timelineItem__1ERxu {
        width: 100%;
    }
}
.gallery_root__3GSRt {
    width: 100%;
    height: 100%;
    position: relative;
}

.gallery_root__3GSRt .slick-dots {
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
}

.gallery_root__3GSRt .slick-dots li button::before {
    color: rgba(241, 243, 245, 0.6);
    opacity: 1 !important;
    font-size: 10px;
}

.gallery_root__3GSRt .slick-dots li.slick-active button::before {
    color: var(--white);
}

@media (max-width: 950px) {
    .gallery_root__3GSRt {
        padding-top: 50px;
    }
}

@media (max-width: 768px) {
    .gallery_root__3GSRt .slick-dots li button::before {
        color: var(--gray-400);
    }

    .gallery_root__3GSRt .slick-dots li.slick-active button::before {
        color: var(--black);
    }
}
.gallerySection_root__3Iv3U {
    width: 100%;
    min-height: 512px;
    display: flex;
    justify-content: center;
    background: linear-gradient(0deg, rgba(42, 54, 79, 0.8), rgba(42, 54, 79, 0.8)),  url(/static/media/galleryBackround.04eb1385.jpg);
    background-size: cover;
}

.gallerySection_wrapper__6agaZ {
    width: 100%;
    max-width: var(--max-width);
    display: grid;
    grid-template-columns: 50% 50%;
    align-self: center;
    padding: 0 120px;
}

.gallerySection_info__1LGyq {
    align-self: center;
    color: var(--white);
    padding-right: 120px;
    display: grid;
    grid-row-gap: 25px;
    row-gap: 25px;
}

.gallerySection_notification__3cSsZ {
    text-decoration: underline;
}

@media (max-width: 1250px) {
    .gallerySection_wrapper__6agaZ {
        padding: 0 60px;
    }

    .gallerySection_info__1LGyq {
        padding-right: 60px;
    }
}

@media (max-width: 768px) {
    .gallerySection_root__3Iv3U {
        background: none;
        min-height: auto;
    }

    .gallerySection_wrapper__6agaZ {
        grid-template-columns: 100%;
        padding: 0 30px;
    }

    .gallerySection_info__1LGyq {
        grid-row-gap: 15px;
        row-gap: 15px;
    }

    .gallerySection_info__1LGyq span {
        font-size: var(--font-size-S);
        color: var(--gray-600);
    }

    .gallerySection_info__1LGyq h2 {
        color: var(--text-color);
        font-size: var(--font-size-L);
    }

    .gallerySection_info__1LGyq p {
        display: none;
    }

    .gallerySection_notification__3cSsZ {
        display: none;
    }
}


.contactPage_root__CnGbP {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contactPage_wrapper__3NGPa {
    width: 100%;
    max-width: var(--max-width);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 220px 0 350px;
    position: relative;
}

.contactPage_wrapper__3NGPa::after,
.contactPage_wrapper__3NGPa::before {
    display: block;
    position: absolute;
    content: '';
    width: 6px;
    bottom: -170px;
    top: -75px;
    background-color: var(--blue);
}

.contactPage_wrapper__3NGPa::before {
    left: 0;
}

.contactPage_wrapper__3NGPa::after {
    right: 0;
}

.contactPage_circle__3Ktlz {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 583px;
    height: 583px;
    border-radius: 50%;
    border-top: 35px solid var(--dark-blue);
    border-left: 35px solid var(--dark-blue);
    border-bottom: 35px solid var(--light-blue);
    border-right: 35px solid var(--light-blue);
    position: relative;
}

.contactPage_circle__3Ktlz::before,
.contactPage_circle__3Ktlz::after {
    display: block;
    content: '';
    position: absolute;
    left: -35px;
    top: -35px;
    width: 583px;
    height: 583px;
    border-radius: 50%;
    border-right: 35px solid transparent;
    border-bottom: 35px solid transparent;
    border-left: 35px solid transparent;
}

.contactPage_circle__3Ktlz::before {
    border-top: 35px solid var(--blue);
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
}

.contactPage_circle__3Ktlz::after {
    border-top: 35px solid var(--blue);
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
}

.contactPage_circle__3Ktlz h2 {
    z-index: 2;
    text-transform: uppercase;
    margin-bottom: 25px;
}

.contactPage_circle__3Ktlz span {
    font-size: var(--font-size-M);
    color: var(--gray-600);
}

.contactPage_cardAddress__3w2tX,
.contactPage_cardContact__1yLnx,
.contactPage_cardSales__VYvyX {
    position: absolute;
    border: 1px solid var(--gray-400);
    box-shadow: 0px 4px 4px rgba(101, 106, 111, 0.3);
    padding: 30px 40px;
    color: var(--text-color);
    background-color: var(--white);
    z-index: 2;
}

.contactPage_cardAddress__3w2tX span,
.contactPage_cardContact__1yLnx span,
.contactPage_cardSales__VYvyX span {
    color: var(--text-color);
}

.contactPage_bold__2C5lc {
    font-weight: var(--weight-semi-bold);
}

.contactPage_cardAddress__3w2tX {
    left: -250px;
    top: 65px;
}

.contactPage_cardContact__1yLnx {
    right: -150px;
    top: -100px;
}

.contactPage_cardSales__VYvyX {
    bottom: -200px;
    right: 20px;
}

.contactPage_cta__jLy1J {
    width: 100%;
    min-height: 330px;
    background: linear-gradient(0deg, rgba(42, 54, 79, 0.8), rgba(42, 54, 79, 0.8)),  url(/static/media/ctaBackground.1cb1e83b.jpg);
    color: var(--white);
    display: grid;
    align-content: center;
    justify-content: center;
    grid-row-gap: 25px;
    row-gap: 25px;
    text-align: center;
}

.contactPage_cta__jLy1J p {
    max-width: 650px;
}

@media (max-width: 1480px) {
    .contactPage_wrapper__3NGPa::before {
        left: 120px;
    }

    .contactPage_wrapper__3NGPa::after {
        right: 120px;
    }
}

@media (max-width: 1300px) {
    .contactPage_wrapper__3NGPa::after,
    .contactPage_wrapper__3NGPa::before {
        display: none;
    }

    .contactPage_cardAddress__3w2tX {
        left: -240px;
    }
}

@media (max-width: 1100px) {
    .contactPage_circle__3Ktlz {
        margin-left: 40px;
    }
}

@media (max-width: 1000px) {
    .contactPage_wrapper__3NGPa {
        margin: 95px 0 120px;
    }

    .contactPage_wrapper__3NGPa::before {
        display: block;
        left: 35px;
        top: 0;
        bottom: 0;
    }

    .contactPage_circle__3Ktlz {
        border: none;
        position: static;
        height: auto;
        width: 100%;
    }

    .contactPage_circle__3Ktlz h2 {
        margin-bottom: 13px;
    }

    .contactPage_circle__3Ktlz::before,
    .contactPage_circle__3Ktlz::after {
        display: none;
    }

    .contactPage_cardAddress__3w2tX,
    .contactPage_cardContact__1yLnx,
    .contactPage_cardSales__VYvyX {
        position: static;
        width: 70%;
        text-align: center;
        margin-bottom: 60px;
    }

    .contactPage_cardAddress__3w2tX {
        margin-top: 45px;
    }

    .contactPage_cardSales__VYvyX {
        margin-bottom: 0;
    }
}

@media (max-width: 768px) {
    .contactPage_circle__3Ktlz h2,
    .contactPage_circle__3Ktlz h3 {
        font-size: var(--font-size-L);
    }

    .contactPage_circle__3Ktlz span {
        font-size: var(--font-size-S);
    }

    .contactPage_circle__3Ktlz {
        margin-left: 0;
    }

    .contactPage_cta__jLy1J {
        font-size: var(--font-size-S);
        grid-row-gap: 10px;
        row-gap: 10px;
        padding: 0 50px;
    }

    .contactPage_cta__jLy1J h2 {
        font-size: var(--font-size-L);
        margin-bottom: 6px;
    }
}

@media (max-width: 500px) {
    .contactPage_cardAddress__3w2tX,
    .contactPage_cardContact__1yLnx, 
    .contactPage_cardSales__VYvyX {
        width: 80%;
    }

    .contactPage_wrapper__3NGPa {
        padding-left: 25px;
    }
}
.materialsSection_root__PQP3B {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 120px 0 180px;
}

.materialsSection_infoSection__1sqJI {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    position: relative;
    margin-bottom: 180px;
}

.materialsSection_infoSection__1sqJI::before {
    display: block;
    content: '';
    position: absolute;
    width: 6px;
    top: 0;
    bottom: 0;
    background-color: var(--blue);
}

.materialsSection_info__PCCxo {
    padding-left: 120px;
    max-width: 580px;
    display: grid;
    grid-row-gap: 25px;
    row-gap: 25px;
    align-content: center;
}

.materialsSection_info__PCCxo span {
    color: var(--gray-600);
}

.materialsSection_image__3CSEL {
    text-align: right;
    position: relative;
}

.materialsSection_image__3CSEL::before {
    display: block;
    content: '';
    position: absolute;
    height: 139px;
    width: 60vw;
    top: 50%;
    left: -50px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background-color: var(--dark-blue);
    z-index: -1;
}

.materialsSection_materials__2Ke2O {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    position: relative;
}

.materialsSection_labelMaterial__3K-_x {
    width: 100%;
    height: 270px;
    background-color: var(--blue);
    color: var(--white);
    display: grid;
    place-items: center;
    padding: 0 50px;
    text-align: center;
    position: relative;
}

.materialsSection_material__3C96U {
    width: 100%;
    height: 270px;
    background-color: var(--white);
    padding: 45px 40px 0;
    border: 1px solid var(--gray-400);
    position: relative;
}

.materialsSection_material__3C96U h4 {
    text-align: center;
    margin-bottom: 21px;
}

.materialsSection_labelMaterial__3K-_x::after,
.materialsSection_material__3C96U::after {
    height: 3px;
    width: 100px;
    display: block;
    content: '';
    position: absolute;
    bottom: 27px;
    right: 51px;
}

.materialsSection_labelMaterial__3K-_x::after {
    background-color: var(--white);
}

.materialsSection_material__3C96U::after {
    background-color: var(--gray-400);
}

.materialsSection_materials__2Ke2O::before {
    display: block;
    content: '';
    position: absolute;
    left: -100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    height: 164px;
    width: 200vw;
    background-color: var(--gray-200);
}

@media (max-width: 1460px) {
    .materialsSection_infoSection__1sqJI::before {
        left: 20px;
    }

    .materialsSection_materials__2Ke2O {
        margin: 0 20px;
    }
}

@media (max-width: 1300px) {
    .materialsSection_image__3CSEL {
        padding: 0 25px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .materialsSection_image__3CSEL img {
        width: 90%;
    }

    .materialsSection_image__3CSEL::before {
        display: none;
    }
}

@media (max-width: 1250px) {
    .materialsSection_info__PCCxo {
        padding-left: 60px;
    }
}

@media (max-width: 1150px) {
    .materialsSection_labelMaterial__3K-_x,
    .materialsSection_material__3C96U {
        height: 320px;
    }
}

@media (max-width: 950px) {
    .materialsSection_labelMaterial__3K-_x,
    .materialsSection_material__3C96U {
        height: 470px;
    }
}

@media (max-width: 768px) {
    .materialsSection_root__PQP3B {
        margin: 95px 0 120px;
    }

    .materialsSection_root__PQP3B span {
        font-size: var(--font-size-S);
    }

    .materialsSection_root__PQP3B h2 {
        font-size: var(--font-size-L);
    }

    .materialsSection_root__PQP3B p {
        font-size: var(--font-size-S);
    }

    .materialsSection_infoSection__1sqJI {
        grid-template-columns: 1fr;
        justify-items: center;
        padding-right: 30px;
        margin-bottom: 90px;
    }

    .materialsSection_image__3CSEL {
        display: none;
    }

    .materialsSection_materials__2Ke2O {
        grid-template-columns: 1fr;
        justify-items: center;
    }

    .materialsSection_labelMaterial__3K-_x,
    .materialsSection_material__3C96U {
        max-width: 80%;
        height: auto;
    }

    .materialsSection_material__3C96U {
        min-height: 210px;
        padding: 25px 20px 45px;
        text-align: center;
    }

    .materialsSection_material__3C96U h4 {
        margin-bottom: 8px;
    }

    .materialsSection_labelMaterial__3K-_x {
        min-height: 170px;
    }
}
.edgesPage_root__36MHS {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.edgesPage_wrapper__31Ce0 {
    width: 100%;
    max-width: var(--max-width);
    display: flex;
    justify-content: center;
}

.edgesPage_structuresWrapper__3sN_U {
    width: 100%;
    min-height: 512px;
    background: linear-gradient(0deg, rgba(42, 54, 79, 0.8), rgba(42, 54, 79, 0.8)),  url(/static/media/structuresBackground.48c1388a.jpg);
    display: flex;
    justify-content: center;
    background-size: cover;
}

.edgesPage_structuresWrapper__3sN_U .edgesPage_wrapper__31Ce0 {
    padding-left: 120px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}

.edgesPage_structures__13xnF {
    display: grid;
    grid-row-gap: 25px;
    row-gap: 25px;
    padding-right: 120px;
}

.edgesPage_structures__13xnF span {
    color: var(--white);
    font-size: var(--font-size-M);
}

.edgesPage_structures__13xnF h2,
.edgesPage_structures__13xnF p {
    color: var(--white);
}

.edgesPage_structuresIcons__1ocHs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: var(--white);
    grid-row-gap: 50px;
    row-gap: 50px;
}

.edgesPage_structuresIcons__1ocHs h5 {
    font-size: var(--font-size-L);
    margin: 12px 0 6px;
}

.edgesPage_icon__3xS8e {
    position: relative;
}

.edgesPage_icon__3xS8e::before {
    display: block;
    position: absolute;
    font-size: 40px;
    color: #A9B3C8;
    font-weight: var(--weight-semi-bold);
    top: -30px;
    left: -55px;
}

.edgesPage_icon__3xS8e:first-child::before {
    content: '1.';
}

.edgesPage_icon__3xS8e:nth-child(2)::before {
    content: '3.';
}

.edgesPage_icon__3xS8e:nth-child(3):before {
    content: '2.';
}

.edgesPage_icon__3xS8e:last-child::before {
    content: '4.';
}

.edgesPage_characteristicsWrapper__3Rf-y {
    width: 100%;
    max-width: var(--max-width);
    margin-top: 120px;
    padding-left: 120px;
}

.edgesPage_characteristicsWrapper__3Rf-y span {
    font-size: var(--font-size-M);
    color: var(--gray-600);
}

.edgesPage_characteristicsWrapper__3Rf-y h2 {
    margin-top: 20px;
}

.edgesPage_characteristics__1yY_d {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin-top: 80px;
}

.edgesPage_characteristic__2wKte {
    max-width: 168px;
}

.edgesPage_characteristic__2wKte div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 168px;
    height: 168px;
    margin-bottom: 45px;
}

.edgesPage_characteristic__2wKte:first-child div,
.edgesPage_characteristic__2wKte:last-child div {
    background-color: var(--dark-blue);
}

.edgesPage_characteristic__2wKte:nth-child(2) div,
.edgesPage_characteristic__2wKte:nth-child(4) div {
    background-color: var(--blue);
}

.edgesPage_characteristic__2wKte:nth-child(3) div {
    background-color: var(--light-blue);
}

.edgesPage_characteristic__2wKte p {
    text-align: center;
    font-size: var(--font-size-M);
}

@media (max-width: 1250px) {
    .edgesPage_structuresWrapper__3sN_U .edgesPage_wrapper__31Ce0,
    .edgesPage_characteristicsWrapper__3Rf-y {
        padding-left: 60px;
    }
}

@media (max-width: 1100px) {
    .edgesPage_structures__13xnF {
        padding-right: 70px;
    }

    .edgesPage_characteristicsWrapper__3Rf-y {
        padding-right: 30px;
    }
}

@media (max-width: 1000px) {
    .edgesPage_characteristics__1yY_d {
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;
        grid-row-gap: 40px;
        row-gap: 40px;
    }
}

@media (max-width: 768px) {
    .edgesPage_structuresWrapper__3sN_U .edgesPage_wrapper__31Ce0 {
        grid-template-columns: 1fr;
        justify-items: center;
        padding: 80px 65px 120px;
    }

    .edgesPage_structures__13xnF {
        padding-right: 0;
        text-align: center;
        margin-bottom: 70px;
    }

    .edgesPage_structures__13xnF span,
    .edgesPage_structures__13xnF p {
        font-size: var(--font-size-S);
    }

    .edgesPage_structures__13xnF h2 {
        font-size: var(--font-size-L);
    }

    .edgesPage_structuresIcons__1ocHs {
        grid-template-columns: 1fr;
    }

    .edgesPage_icon__3xS8e {
        text-align: center;
    }

    .edgesPage_icon__3xS8e::before {
        display: none;
    }

    .edgesPage_icon__3xS8e h5 {
        font-weight: var(--weight-semi-bold);
    }

    .edgesPage_icon__3xS8e p {
        font-size: var(--font-size-S);
    }

    .edgesPage_characteristicsWrapper__3Rf-y {
        text-align: center;
        margin-top: 90px;
        padding-left: 30px;
    }

    .edgesPage_characteristicsWrapper__3Rf-y span {
        font-size: var(--font-size-S);
    }

    .edgesPage_characteristicsWrapper__3Rf-y h2 {
        font-size: var(--font-size-L);
        margin-top: 8px;
    }

    .edgesPage_characteristics__1yY_d {
        margin-top: 60px;
    }

    .edgesPage_characteristic__2wKte p {
        font-size: var(--font-size-S);
    }
}

@media (max-width: 650px) {
    .edgesPage_characteristics__1yY_d {
        grid-template-columns: repeat(2, 1fr);
    }

    .edgesPage_characteristic__2wKte {
        display: grid;
        justify-items: center;
    }

    .edgesPage_characteristic__2wKte div {
        width: 130px;
        height: 130px;
    }
}

@media (max-width: 400px) {
    .edgesPage_characteristics__1yY_d {
        grid-template-columns: 1fr;
    }
}


.packagesPage_root__-w_nW {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.packagesPage_wrapper__1r-uv {
    width: 100%;
    max-width: var(--max-width);
    display: flex;
    justify-content: center;
}

.packagesPage_formsWrapper__3HNG5 {

    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas: 
    "info image"
    "graphic image";
    padding-left: 120px;
}

.packagesPage_info__36KLo {
    grid-area: info;
    position: relative;
    display: grid;
    grid-row-gap: 25px;
    row-gap: 25px;
    align-self: center;
    margin-bottom: 30px;
}

.packagesPage_info__36KLo span {
    font-size: var(--font-size-M);
    color: var(--gray-600);
}

.packagesPage_info__36KLo::before {
    display: block;
    content: '';
    position: absolute;
    width: 6px;
    top: 0;
    left: -120px;
    bottom: 0;
    background-color: var(--blue);
}

.packagesPage_graphic__6_l7U {
    grid-area: graphic;
}

.packagesPage_graphic__6_l7U img {
    max-width: 540px;
    width: 100%;
    height: auto;
}

.packagesPage_image__24jA6 {
    grid-area: image;
}

.packagesPage_image__24jA6 img {
    max-width: 608px;
    width: 100%;
    height: auto;
}

@media (max-width: 1460px) {
    .packagesPage_info__36KLo::before {
        left: -100px;
    }
}

@media (max-width: 1250px) {
    .packagesPage_formsWrapper__3HNG5 {
        padding-left: 60px;
    }

    .packagesPage_info__36KLo::before {
        left: -40px;
    }
}

@media (max-width: 768px) {
    .packagesPage_formsWrapper__3HNG5 {
        grid-template-columns: 100%;
        grid-template-areas: 
        "info"
        "graphic"
        "image";
        padding-left: 0;
        grid-row-gap: 50px;
        row-gap: 50px;
    }

    .packagesPage_info__36KLo {
        padding: 0 30px 0 60px;
    }

    .packagesPage_info__36KLo span,
    .packagesPage_info__36KLo p {
        font-size: var(--font-size-S);
    }

    .packagesPage_info__36KLo h2 {
        font-size: var(--font-size-L);
    }

    .packagesPage_info__36KLo::before {
        left: 25px;
    }

    .packagesPage_graphic__6_l7U,
    .packagesPage_image__24jA6 {
        padding: 0 30px;
    }
}
.menuHamburger_hamburger__ST37Y {
  padding: 15px 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; 
}
.menuHamburger_hamburger__ST37Y:hover {
    opacity: 0.7; 
}

.menuHamburger_hamburger__ST37Y.menuHamburger_isActive__1MoS9:hover {
    opacity: 0.7; 
}
.menuHamburger_hamburger__ST37Y.menuHamburger_isActive__1MoS9 .menuHamburger_hamburger-inner__e0Q94,
.menuHamburger_hamburger__ST37Y.menuHamburger_isActive__1MoS9 .menuHamburger_hamburger-inner__e0Q94::before,
.menuHamburger_hamburger__ST37Y.menuHamburger_isActive__1MoS9 .menuHamburger_hamburger-inner__e0Q94::after {
    background-color: var(--text-color); 
}

.menuHamburger_hamburgerBox__1BzXU {
  width: 29px;
  height: 25px;
  display: inline-block;
  position: relative; 
}

.menuHamburger_hamburgerInner__1KmDe {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.menuHamburger_hamburgerInner__1KmDe, .menuHamburger_hamburgerInner__1KmDe::before, .menuHamburger_hamburgerInner__1KmDe::after {
    width: 29px;
    height: 4px;
    background-color: var(--text-color);
    position: absolute;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}

.menuHamburger_hamburgerInner__1KmDe::before, .menuHamburger_hamburgerInner__1KmDe::after {
content: "";
display: block; 
}

.menuHamburger_hamburgerInner__1KmDe::before {
    top: -10px; 
}

.menuHamburger_hamburgerInner__1KmDe::after {
    bottom: -10px; 
}

.menuHamburger_hamburgerCollapse__2mQox .menuHamburger_hamburgerInner__1KmDe {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); 
}

.menuHamburger_hamburgerCollapse__2mQox .menuHamburger_hamburgerInner__1KmDe::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; 
}

.menuHamburger_hamburgerCollapse__2mQox .menuHamburger_hamburgerInner__1KmDe::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); 
}

.menuHamburger_hamburgerCollapse__2mQox.menuHamburger_isActive__1MoS9 .menuHamburger_hamburgerInner__1KmDe {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
          transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); 
}

.menuHamburger_hamburgerCollapse__2mQox.menuHamburger_isActive__1MoS9 .menuHamburger_hamburgerInner__1KmDe::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; 
}

.menuHamburger_hamburgerCollapse__2mQox.menuHamburger_isActive__1MoS9 .menuHamburger_hamburgerInner__1KmDe::before {
    top: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); 
}
.mobileMenu_root__1lMAr {
    position: fixed;
    top: 70px;
    left: 50%;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: calc(100% - 65px);
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    z-index: 10;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
    transition-duration: 192ms;
    transition-timing-function: var(--anim-out);
    transition-property: opacity, visibility, -webkit-transform;
    transition-property: opacity, transform, visibility;
    transition-property: opacity, transform, visibility, -webkit-transform;
}

.mobileMenu_rootOpen__3LSSY {

    opacity: 0.95;
    -webkit-transform: scale(1) translateX(-50%);
            transform: scale(1) translateX(-50%);
    transition-duration: 224ms;
    transition-timing-function: var(--anim-in);
    visibility: visible;
    pointer-events: auto;
}

.mobileMenu_navigation__1R1FP {
    width: 100%;
}

.mobileMenu_navigation__1R1FP ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0;
    align-items: center;
}

.mobileMenu_navigation__1R1FP ul li {
    padding: 17px 0;
    font-weight: var(--weight-semi-bold);
    width: 100%;
    height: 100%;
    text-align: center;
    position: relative;
}

.mobileMenu_navigation__1R1FP a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
}

.mobileMenu_navigation__1R1FP a svg {
    width: 22px;
    height: 22px;
    position: absolute;
    top: 50%;
    left: calc(50% + 32px);
    fill: var(--blue);
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.mobileMenu_isActive__1wMLC a {
    color: var(--blue);
}

.mobileMenu_haveSubmenu__2zvPw {
    background-color: rgba(99, 114, 146, 0.2);
}

.mobileMenu_isOpen__1FQaQ {
    padding-bottom: 0 !important;
}

.mobileMenu_submenu__36wyq {
    visibility: hidden;
    opacity: 0;
    display: none !important;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    margin-top: 17px;
}

.mobileMenu_submenu__36wyq li {
    background-color: rgba(37, 42, 47, 0.05);
}

.mobileMenu_submenuOpen__2U0vC {

    visibility: visible;
    opacity: 1;
    display: block !important;
    -webkit-animation: mobileMenu_growDown__3WRkV 300ms ease-in-out forwards;
            animation: mobileMenu_growDown__3WRkV 300ms ease-in-out forwards;
    -webkit-transform-origin: top center;
            transform-origin: top center;
}

@-webkit-keyframes mobileMenu_growDown__3WRkV {
    0% {
        -webkit-transform: scaleY(0);
                transform: scaleY(0)
    }
    80% {
        -webkit-transform: scaleY(1.1);
                transform: scaleY(1.1)
    }
    100% {
        -webkit-transform: scaleY(1);
                transform: scaleY(1)
    }
}

@keyframes mobileMenu_growDown__3WRkV {
    0% {
        -webkit-transform: scaleY(0);
                transform: scaleY(0)
    }
    80% {
        -webkit-transform: scaleY(1.1);
                transform: scaleY(1.1)
    }
    100% {
        -webkit-transform: scaleY(1);
                transform: scaleY(1)
    }
}
.header_root__fUxGr {
    width: 100%;
    height: 100px;
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
}

.header_navigation__1N1pj {
    height: 100%;
    display: flex;
    margin-right: 35px;
}

.header_menu__3_Gpn {
    display: flex;
    align-items: center;
    height: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.header_menuItem__LTJbm {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 40px;
    font-weight: var(--weight-semi-bold);
    cursor: pointer;
}

.header_withChildren__1ECbV {
    background-color: rgba(53, 67, 93, 0.7);
    position: relative;
}

.header_withChildren__1ECbV:hover > .header_submenu__1H-BT {
    display: flex;
}

.header_withChildren__1ECbV > a {
    color: var(--white);
}

.header_menuItem__LTJbm a {
    position: relative;
}

.header_menuItem__LTJbm:hover > a {
    color: var(--blue);
}

.header_withChildren__1ECbV:hover > a {
    color: var(--white);
}

.header_menuItem__LTJbm > a::after {
    display: block;
    position: absolute;
    content: '';
    height: 1px;
    width: 100%;
    bottom: -3px;
    background-color: var(--blue);
    opacity: 0;
    overflow: hidden;
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms;
    transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
}

.header_withChildren__1ECbV > a::after {
    display: block;
    position: absolute;
    content: '';
    height: 1px;
    width: 100%;
    bottom: -3px;
    background-color: var(--white);
    opacity: 0;
    overflow: hidden;
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms;
    transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
}

.header_menuItem__LTJbm:hover > a::after {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.header_withChildren__1ECbV:hover > a::after {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.header_arrowDown__3eBao {
    stroke: var(--white);
    fill: var(--white);
    position: absolute;
    bottom: 10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.header_logo__3iSgw {
    display: flex;
    align-items: center;
    padding: 0 55px;
}

.header_submenu__1H-BT {
    display: none;
    flex-direction: column;
    position: absolute;
    bottom: -98px;
    right: 0;
    list-style-type: none;
}

.header_submenu__1H-BT li {
    width: 350px;
    height: 49px;
    background-color: var(--white);
    padding: 0 15px 0 28px;
    display: flex;
    align-items: center;
    z-index: 10;
}

.header_submenu__1H-BT li:first-child {
    border-bottom: 1px solid var(--grenade);
}

.header_submenu__1H-BT a {
    color: var(--text-color);
}

.header_submenu__1H-BT li:hover a {
    color: var(--blue);
}

.header_square__32Qdi {
    width: 8px;
    height: 8px;
    background-color: var(--dark-blue);
    margin-right: 17px;
}

@media (max-width: 1024px) {
    .header_navigation__1N1pj {
        margin-right: 0;
    }
}

@media (max-width: 1000px) {
    .header_root__fUxGr {
        justify-content: space-between;
        padding: 0 40px;
    }

    .header_logo__3iSgw {
        padding: 0;
    }
}

@media (max-width: 768px) {
    .header_root__fUxGr {
        height: 82px;
    }

    .header_logo__3iSgw img {
        width: 173px;
        height: auto;
    }
}
.footer_root__1F9TO {
    width: 100%;
    min-height: 600px;
    display: flex;
    align-items: stretch;
    justify-content: center;
    background-image: url(/static/media/footerBackground.45a6de1a.png);
    background-size: cover;
    margin: 0;
    padding: 0;
    position: relative;
}

.footer_root__1F9TO::before {
    display: block;
    content: '';
    height: 20px;
    width: 65%;
    background-color: var(--blue);
    position: absolute;
    top: -20px;
    right: 0;
}

.footer_root__1F9TO::after {
    display: block;
    content: '';
    height: 53px;
    width: 100%;
    background-color: var(--grenade);
    position: absolute;
    bottom: 0;
}

.footer_wrapper__1k81- {
    width: 100%;
    max-width: var(--max-width);
    height: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    position: relative;
    padding: 130px 0 53px;
}

.footer_logo__3Op2T {
    position: absolute;
    left: 100px;
    top: 55px;
}

.footer_col__1Dj69 {
    color: var(--white);
    padding: 0 25px;
    font-size: var(--font-size-M);
}

@media (max-width: 1000px) {
    .footer_root__1F9TO::before {
        height: 11px;
        width: 75%;
        top: -11px;
    }

    .footer_root__1F9TO::after {
        height: 34px;
    }

    .footer_wrapper__1k81- {
        grid-template-columns: 1fr;
        justify-items: center;
        padding: 48px 0 100px;
        grid-row-gap: 35px;
        row-gap: 35px;
    }

    .footer_logo__3Op2T {
        position: static;
        margin-bottom: 64px;
    }

    .footer_col__1Dj69 {
        width: 60%
    }
}

@media (max-width: 500px) {
    .footer_col__1Dj69 {
        font-size: var(--font-size-S);
        width: 80%;
    }

    .footer_logo__3Op2T {
        width: 180px;
        height: auto;
    }
}
.brandSection_root__28l6W {
    width: 100%;
    display: grid;
    place-items: center;
    margin: 140px 0;
}

.brandSection_wrapper__1Pi2X {
    max-width: var(--max-width);
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-column-gap: 95px;
    -webkit-column-gap: 95px;
            column-gap: 95px;
}

.brandSection_wrapper__1Pi2X img {
    -ms-interpolation-mode: nearest-neighbor;
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
}

.brandSection_rootMobile__2z1os {
    margin: 140px 0;
}

.brandSection_rootMobile__2z1os img {
    width: auto !important;
    height: 30px;
}

.brandSection_rootMobile__2z1os .slick-slide {
    display: flex;
    justify-content: center;
}

.brandSection_titleArea__2K7wc {
    width: 100%;
    max-width: var(--max-width);
    padding-left: 120px;
    margin-bottom: 60px;
}

.brandSection_title__1eSDL {
    font-size: var(--font-size-XXL);
    color: rgba(85, 94, 114, 0.6);
}

@media (max-width: 960px) {
    .brandSection_wrapper__1Pi2X {
        grid-column-gap: 50px;
        -webkit-column-gap: 50px;
                column-gap: 50px;
    }

    .brandSection_titleArea__2K7wc {
        padding-left: 60px;
    }
}

@media (max-width: 830px) {
    .brandSection_wrapper__1Pi2X {
        grid-template-columns: repeat(2, auto);
        justify-items: center;
        grid-row-gap: 45px;
        row-gap: 45px;
        grid-column-gap: 75px;
        -webkit-column-gap: 75px;
                column-gap: 75px;
    }
}

@media (max-width: 768px) {
    .brandSection_title__1eSDL {
        font-size: var(--font-size-XL);
    }
}

@media (max-width: 380px) {
    .brandSection_rootMobile__2z1os img {
        height: 40px;
    }

    .brandSection_titleArea__2K7wc {
        text-align: center;
        padding-left: 0;
    }
}
.maintenancePage_root__--aW1 {
    width: 100vw;
    height: 100vh;
    background-color: var(--dark-blue);
    position: relative;
}

.maintenancePage_logo__19nqw {
    position: absolute;
    top: 30px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.maintenancePage_content__17KW6 {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.maintenancePage_title__19mIP, .maintenancePage_paragraph__1gIeW {
    color: var(--white);
}
:root {
  /* Colors */

  --white: #FFFFFF;
  --black: #000000;
  --dark-blue: #485878;
  --blue: #637292;
  --light-grenade: #2A364FCC;
  --light-blue: #A9B3C8;
  --grenade: #35435D;
  --text-color: #1B2229;
  --gray-200: #F1F3F5;
  --gray-300: #F4F4F4;
  --gray-400: #A7ACB2;
  --gray-600: #656A6F;

  /* Font weight */

  --weight-normal: 400;
  --weight-semi-bold: 500;
  --weight-bold: 700;

  /* Animations */

  --anim-bounce: cubic-bezier(0.5, 1.8, 0.9, 0.8);
  --anim-in: cubic-bezier(0, 0, 0.2, 1);
  --anim-out: cubic-bezier(0.4, 0, 1, 1);
  --anim-standard: cubic-bezier(0.4, 0, 0.2, 1);

  /* Dimensions */

  --max-width: 1440px;

  /* Font size */

  --font-size-XS: 9px;
  --font-size-S: 12px;
  --font-size-M: 14px;
  --font-size-L: 16px;
  --font-size-XL: 20px;
  --font-size-XXL: 24px;
  --font-size-XXXL: 32px;
  --font-size-XXXXL: 36px;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
    background-color: var(--white);
    font-size: 100%;
    font-weight: var(--weight-normal);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
  overflow-x: hidden;
}

body,
button,
input,
select,
textarea {
    color: var(--text-color);
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
}

a {
  text-decoration: none;
  color: var(--text-color);
}

a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  margin: 0;
  padding: 0;
}








