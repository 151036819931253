@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

:global(:root) {
  /* Colors */

  --white: #FFFFFF;
  --black: #000000;
  --dark-blue: #485878;
  --blue: #637292;
  --light-grenade: #2A364FCC;
  --light-blue: #A9B3C8;
  --grenade: #35435D;
  --text-color: #1B2229;
  --gray-200: #F1F3F5;
  --gray-300: #F4F4F4;
  --gray-400: #A7ACB2;
  --gray-600: #656A6F;

  /* Font weight */

  --weight-normal: 400;
  --weight-semi-bold: 500;
  --weight-bold: 700;

  /* Animations */

  --anim-bounce: cubic-bezier(0.5, 1.8, 0.9, 0.8);
  --anim-in: cubic-bezier(0, 0, 0.2, 1);
  --anim-out: cubic-bezier(0.4, 0, 1, 1);
  --anim-standard: cubic-bezier(0.4, 0, 0.2, 1);

  /* Dimensions */

  --max-width: 1440px;

  /* Font size */

  --font-size-XS: 9px;
  --font-size-S: 12px;
  --font-size-M: 14px;
  --font-size-L: 16px;
  --font-size-XL: 20px;
  --font-size-XXL: 24px;
  --font-size-XXXL: 32px;
  --font-size-XXXXL: 36px;
}

:global(*),
:global(*::after),
:global(*::before) {
  box-sizing: border-box;
}

:global(html) {
    background-color: var(--white);
    font-size: 100%;
    font-weight: var(--weight-normal);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    height: 100%;
    margin: 0;
    padding: 0;
}

:global(body) {
  overflow-x: hidden;
}

:global(body),
:global(button),
:global(input),
:global(select),
:global(textarea) {
    color: var(--text-color);
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
}

:global(a) {
  text-decoration: none;
  color: var(--text-color);
}

:global(a),
:global(p),
:global(h1),
:global(h2),
:global(h3),
:global(h4),
:global(h5),
:global(h6),
:global(span) {
  margin: 0;
  padding: 0;
}







