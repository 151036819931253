.hamburger {
  padding: 15px 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; 
}
.hamburger:hover {
    opacity: 0.7; 
}

.hamburger.isActive:hover {
    opacity: 0.7; 
}
.hamburger.isActive .hamburger-inner,
.hamburger.isActive .hamburger-inner::before,
.hamburger.isActive .hamburger-inner::after {
    background-color: var(--text-color); 
}

.hamburgerBox {
  width: 29px;
  height: 25px;
  display: inline-block;
  position: relative; 
}

.hamburgerInner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburgerInner, .hamburgerInner::before, .hamburgerInner::after {
    width: 29px;
    height: 4px;
    background-color: var(--text-color);
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}

.hamburgerInner::before, .hamburgerInner::after {
content: "";
display: block; 
}

.hamburgerInner::before {
    top: -10px; 
}

.hamburgerInner::after {
    bottom: -10px; 
}

.hamburgerCollapse .hamburgerInner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); 
}

.hamburgerCollapse .hamburgerInner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; 
}

.hamburgerCollapse .hamburgerInner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); 
}

.hamburgerCollapse.isActive .hamburgerInner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); 
}

.hamburgerCollapse.isActive .hamburgerInner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; 
}

.hamburgerCollapse.isActive .hamburgerInner::before {
    top: 0;
    transform: rotate(-90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); 
}