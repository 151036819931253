.root {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
}

.rootFullScreen {
    composes: root;

    width: 100vw;
    height: 100vh;
    background: var(--white);
    z-index: 1000;
    position: fixed;
    left: 0;
    top: 0;
}