.root {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 200px;
    position: relative;
}

.root::before {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 387px;
    width: 40%;
    background-color: var(--dark-blue);
}

.wrapper {
    width: 100%;
    max-width: var(--max-width);
    display: flex;
}

.imageSection {
    height: 387px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
}

.infoSection {
    width: 50%;
}

@media (max-width: 768px) {
    .imageSection {
        display: none;
    }

    .infoSection {
        width: 100%;
    }

    .root {
        margin-top: 110px;
    }
}