.root {
    width: 100%;
    height: 627px;
    background-color: var(--gray-200);
    box-shadow: 0px 4px 4px rgba(101, 106, 111, 0.25);
    display: flex;
    align-items: center;
    padding-left: 15%;
    position: relative;
}

.root::before {
    position: absolute;
    display: block;
    content: '';
    width: 40%;
    height: 6px;
    top: -6px;
    left: 0;
    background-color: var(--blue);
}

.infoSection {
    max-width: 420px;
}

.preTitle {
    color: var(--gray-600);
    font-size: var(--font-size-L);
}

.title {
    font-size: var(--font-size-XXL);
    margin: 20px 0;
}

.paragraph {
    font-weight: var(--weight-bold);
}

.list {
    margin: 0;
    padding-left: 29px;
}

.stepsSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 40px 0 40px;
}

.dot {
    display: block;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: radial-gradient(38.24% 38.24% at 50% 50%, #637292 0%, #485878 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-weight: var(--weight-bold);
    font-size: var(--font-size-XXXXL);
    margin: 20px 0;
    position: relative;
}

.dot:nth-child(2)::after,
.dot:nth-child(3)::after,
.dot:nth-child(4)::after {
    position: absolute;
    display: block;
    content: '';
    width: 72px;
    height: 72px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
    border: 1px solid var(--dark-blue);
    border-radius: 50%;
}

.dot:nth-child(1)::before,
.dot:nth-child(2)::before,
.dot:nth-child(3)::before {
    position: absolute;
    display: block;
    width: 1px;
    height: 33px;
    content: '';
    bottom: -36px;
    left: 50%;
    background-color: var(--dark-blue);
}

.dot:nth-child(1)::before {
    height: 37px;
}

@media (max-width: 1100px) {
    .root {
        padding-left: 10%;
    }

    .stepsSection {
        padding: 50px 30px 0 20px;
    }
}

@media (max-width: 900px) {
    .root {
        height: 800px;
    }
}

@media (max-width: 768px) {
    .root {
        box-shadow: none;
        background-color: transparent;
        background-image: url('../../../assets/images/technologyBackground.png');
        background-repeat: no-repeat;
        background-size: cover;
        justify-content: center;
        position: relative;
        color: var(--white);
        padding: 0 34px;
        height: 520px; 
    }

    .root::before {
        display: none;
    }

    .root::after {
        position: absolute;
        display: block;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(42, 54, 79, 0.8);
    }

    .stepsSection {
        display: none;
    }

    .infoSection {
        z-index: 2;
        font-size: var(--font-size-M);
    }

    .title {
        font-size: var(--font-size-XL);
    }

    .preTitle {
        color: var(--white);
    }
}

@media (max-width: 500px) {
    .title {
        font-size: var(--font-size-L);
    }

    .preTitle {
        font-size: var(--font-size-S);
    }
}