.root {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.wrapper {
    width: 100%;
    max-width: var(--max-width);
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-left: 120px;
}

.wrapper::before {
    display: block;
    content: '';
    position: absolute;
    left: 0px;
    top: 0;
    bottom: 120px;
    width: 6px;
    background-color: var(--blue);
}

.info {
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
    margin-bottom: 120px;
}

.pretitle {
    color: var(--gray-600);
    margin-bottom: 24px;
}

.title {
    margin-bottom: 24px;
}

.description {
    margin-bottom: 50px;
    padding: 0 80px;
}

.pretitle,
.title,
.description {
    padding-right: 120px;
}

.timelineWrapper {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin-bottom: 110px;
    margin-right: 30px;
}

.timelineItem {
    padding: 0 10px;
}

.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: left;
}

.accordion {
    display: flex;
    flex-direction: column;
}

.accordionPretitle {
    composes: pretitle;
}

.accordionTitle {
    composes: title;
}

.accordionDescription {
    margin-bottom: 20px;
}

.image {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

.image img {
    width: 100%;
    max-width: 515px;
    height: auto;
}

.sentence {
    position: absolute;
    width: 420px;
    height: 100px;
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 60px;
    left: 0;
    box-shadow: 0px 2px 4px rgba(101, 106, 111, 0.3);
    border-radius: 1px 8px 1px;
}

.quot {
    font-style: italic;
    position: absolute;
    top: 0px;
    left: 10px;
    font-weight: var(--weight-bold);
    color: rgba(72, 88, 120, 0.4);
    font-size: 60px;
}

.advantagesWrapper {
    composes: wrapper;

    position: relative;
    padding-left: 0;
    margin-bottom: 80px;
    padding: 0;
    padding-left: 0 !important;
}

.advantagesWrapper::after {
    display: block;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: -50px;
    width: 6px;
    background-color: var(--blue);
}

.advantagesWrapper::before {
    display: none;
}

.advantages {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0 105px;
}

.advantages span {
    margin-bottom: 20px;
    color: var(--gray-600);
}

.advantages h2 {
    margin-bottom: 100px;
}

.items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: left;
    row-gap: 70px;
}

.item {
    padding: 0 48px;
    position: relative;
}

.item::before {
    display: block;
    content: '';
    position: absolute;
    top: 6px;
    left: 0;
    background-color: var(--blue);
    width: 16px;
    height: 16px;
}

@media (max-width: 1460px) {
    .wrapper::before {
        left: 20px;
    }

    .title, 
    .pretitle {
        padding-right: 0;
    }

    .description {
        padding: 0 30px;
    }

    .advantagesWrapper::after {
        display: none;
    }
}

@media (max-width: 1250px) {
    .wrapper {
        padding-left: 60px;
    }

    .sentence {
        left: 50px;
    }
}

@media (max-width: 1200px) {
    .container {
        column-gap: 25px;
    }
}

@media (max-width: 1000px) {
    .container {
        grid-template-columns: 1fr;
        text-align: center;
    }

    .sentence {
        display: none;
    }

    .image {
        justify-content: center;
        margin-top: 100px;
    }

    .accordion {
        align-items: center;
    }

    .accordion > div {
        margin-top: 60px;
    }

    .accordionDescription {
        padding: 0 30px;
    }

    .advantages {
        padding: 0 50px;
    }
}

@media (max-width: 768px) {
    .wrapper {
        margin-top: 100px;
        padding: 0 30px;
    }

    .wrapper::before {
        display: none;
    }

    .info {
        margin-bottom: 100px;
    }

    .info span,
    .info p {
        font-size: var(--font-size-S);
    }

    .info h2 {
        font-size: var(--font-size-L);
    }

    .image {
        display: none;
    }

    .description {
        margin-bottom: 50px;
    }

    .timelineWrapper {
        grid-template-columns: 1fr;
        margin-right: 0;
        justify-items: center;
    }

    .timelineItem {
        margin-bottom: 20px;
        width: 75%;
    }

    .timelineItem:last-child {
        margin-bottom: 0;
    }

    .accordion > div {
        margin-top: 25px;
    }

    .pretitle,
    .title {
        margin-bottom: 15px;
    }

    .accordionDescription,
    .description {
        padding: 0;
    }

    .advantagesWrapper {
        padding: 0;
        margin-bottom: 0;
    }

    .advantages {
        padding: 0;
    }

    .advantages h2 {
        font-size: var(--font-size-L);
        margin-bottom: 60px;
    }

    .advantages span {
        font-size: var(--font-size-S);
        margin-bottom: 14px;
    }

    .items h3 {
        font-size: var(--font-size-L);
        position: relative;
        margin-bottom: 14px;
    }

    .items p {
        font-size: var(--font-size-S);
    }

    .items {
        grid-template-columns: 1fr;
        width: 100%;
        padding: 0 75px;
    }

    .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0;
    }

    .item::before {
        display: none;
    }

    .item h3::before {
        display: block;
        position: absolute;
        top: 50%;
        left: -16px;
        transform: translateY(-50%);
        content: '';
        width: 8px;
        height: 8px;
        background-color: var(--blue);
    }
}

@media (max-width: 500px) {
    .timelineItem {
        width: 100%;
    }
}