.root {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wrapper {
    width: 100%;
    max-width: var(--max-width);
    display: flex;
    justify-content: center;
}

.structuresWrapper {
    width: 100%;
    min-height: 512px;
    background: linear-gradient(0deg, rgba(42, 54, 79, 0.8), rgba(42, 54, 79, 0.8)),  url('../../assets/images/structuresBackground.jpg');
    display: flex;
    justify-content: center;
    background-size: cover;
}

.structuresWrapper .wrapper {
    padding-left: 120px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}

.structures {
    display: grid;
    row-gap: 25px;
    padding-right: 120px;
}

.structures span {
    color: var(--white);
    font-size: var(--font-size-M);
}

.structures h2,
.structures p {
    color: var(--white);
}

.structuresIcons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: var(--white);
    row-gap: 50px;
}

.structuresIcons h5 {
    font-size: var(--font-size-L);
    margin: 12px 0 6px;
}

.icon {
    position: relative;
}

.icon::before {
    display: block;
    position: absolute;
    font-size: 40px;
    color: #A9B3C8;
    font-weight: var(--weight-semi-bold);
    top: -30px;
    left: -55px;
}

.icon:first-child::before {
    content: '1.';
}

.icon:nth-child(2)::before {
    content: '3.';
}

.icon:nth-child(3):before {
    content: '2.';
}

.icon:last-child::before {
    content: '4.';
}

.characteristicsWrapper {
    width: 100%;
    max-width: var(--max-width);
    margin-top: 120px;
    padding-left: 120px;
}

.characteristicsWrapper span {
    font-size: var(--font-size-M);
    color: var(--gray-600);
}

.characteristicsWrapper h2 {
    margin-top: 20px;
}

.characteristics {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin-top: 80px;
}

.characteristic {
    max-width: 168px;
}

.characteristic div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 168px;
    height: 168px;
    margin-bottom: 45px;
}

.characteristic:first-child div,
.characteristic:last-child div {
    background-color: var(--dark-blue);
}

.characteristic:nth-child(2) div,
.characteristic:nth-child(4) div {
    background-color: var(--blue);
}

.characteristic:nth-child(3) div {
    background-color: var(--light-blue);
}

.characteristic p {
    text-align: center;
    font-size: var(--font-size-M);
}

@media (max-width: 1250px) {
    .structuresWrapper .wrapper,
    .characteristicsWrapper {
        padding-left: 60px;
    }
}

@media (max-width: 1100px) {
    .structures {
        padding-right: 70px;
    }

    .characteristicsWrapper {
        padding-right: 30px;
    }
}

@media (max-width: 1000px) {
    .characteristics {
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;
        row-gap: 40px;
    }
}

@media (max-width: 768px) {
    .structuresWrapper .wrapper {
        grid-template-columns: 1fr;
        justify-items: center;
        padding: 80px 65px 120px;
    }

    .structures {
        padding-right: 0;
        text-align: center;
        margin-bottom: 70px;
    }

    .structures span,
    .structures p {
        font-size: var(--font-size-S);
    }

    .structures h2 {
        font-size: var(--font-size-L);
    }

    .structuresIcons {
        grid-template-columns: 1fr;
    }

    .icon {
        text-align: center;
    }

    .icon::before {
        display: none;
    }

    .icon h5 {
        font-weight: var(--weight-semi-bold);
    }

    .icon p {
        font-size: var(--font-size-S);
    }

    .characteristicsWrapper {
        text-align: center;
        margin-top: 90px;
        padding-left: 30px;
    }

    .characteristicsWrapper span {
        font-size: var(--font-size-S);
    }

    .characteristicsWrapper h2 {
        font-size: var(--font-size-L);
        margin-top: 8px;
    }

    .characteristics {
        margin-top: 60px;
    }

    .characteristic p {
        font-size: var(--font-size-S);
    }
}

@media (max-width: 650px) {
    .characteristics {
        grid-template-columns: repeat(2, 1fr);
    }

    .characteristic {
        display: grid;
        justify-items: center;
    }

    .characteristic div {
        width: 130px;
        height: 130px;
    }
}

@media (max-width: 400px) {
    .characteristics {
        grid-template-columns: 1fr;
    }
}

