.root {
    width: 100%;
    min-height: 600px;
    display: flex;
    align-items: stretch;
    justify-content: center;
    background-image: url('../../assets/images/footerBackground.png');
    background-size: cover;
    margin: 0;
    padding: 0;
    position: relative;
}

.root::before {
    display: block;
    content: '';
    height: 20px;
    width: 65%;
    background-color: var(--blue);
    position: absolute;
    top: -20px;
    right: 0;
}

.root::after {
    display: block;
    content: '';
    height: 53px;
    width: 100%;
    background-color: var(--grenade);
    position: absolute;
    bottom: 0;
}

.wrapper {
    width: 100%;
    max-width: var(--max-width);
    height: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    position: relative;
    padding: 130px 0 53px;
}

.logo {
    position: absolute;
    left: 100px;
    top: 55px;
}

.col {
    color: var(--white);
    padding: 0 25px;
    font-size: var(--font-size-M);
}

@media (max-width: 1000px) {
    .root::before {
        height: 11px;
        width: 75%;
        top: -11px;
    }

    .root::after {
        height: 34px;
    }

    .wrapper {
        grid-template-columns: 1fr;
        justify-items: center;
        padding: 48px 0 100px;
        row-gap: 35px;
    }

    .logo {
        position: static;
        margin-bottom: 64px;
    }

    .col {
        width: 60%
    }
}

@media (max-width: 500px) {
    .col {
        font-size: var(--font-size-S);
        width: 80%;
    }

    .logo {
        width: 180px;
        height: auto;
    }
}