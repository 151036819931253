.root {
    width: 100%;
    max-width: 580px;
}

.heading {
    display: flex;
    align-items: center;
    height: 48px;
    width: 100%;
    cursor: pointer;
    border-bottom: 1px solid var(--text-color);
}

.button {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0 22px
}

.button[aria-expanded='true'] {
    background-color: var(--gray-200);
}

.button svg {
    margin-right: 15px;
}

.panel {
    background-color: var(--gray-200);
    padding: 30px 22px;
    width: 100%;
    box-sizing: border-box;
}

.content {
    margin: 0;
    animation: anim 200ms var(--anim-bounce);
}

[hidden] {
    display: none;
}

@keyframes anim {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .heading {
        font-size: var(--font-size-S);
        text-align: left;
    }
}