.root {
    white-space: nowrap;
    pointer-events: auto;
    cursor: pointer;
    min-width: 156px;
    height: 44px;
    max-width: 100%;
    padding: 0 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    font-weight: var(--weight-semi-bold);
    transition-duration: 100ms;
    transition-property: background-color, color;
    transition-timing-function: var(--anim-standard);
}

.root_normalPriority {
    composes: root;

    background-color: var(--dark-blue);
    color: var(--gray-200);
    box-shadow: 0px 2px 4px rgba(42, 54, 79, 0.5);
}

.root_normalPriority:hover {
    background-color: var(--gray-300);
    color: var(--text-color);
}

.root_whitePriority {
    composes: root;

    background-color: var(--gray-300);
    color: var(--text-color);
    box-shadow: 0px 1px 4px #A7ACB2;
}

.root_whitePriority:hover {
    background-color: var(--dark-blue);
    color: var(--gray-200);
    box-shadow: 0px 2px 4px rgba(42, 54, 79, 0.5);
}