.root {
    width: 100vw;
    height: 100vh;
    background-color: var(--dark-blue);
    position: relative;
}

.logo {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
}

.content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.title, .paragraph {
    color: var(--white);
}