.root {
    display: grid;
    place-items: center;
    position: relative;
}

.root::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 70%;
    height: 20px;
    background-color: var(--blue);
}

.background {
    display: grid;
    place-items: center;
    height: 100%;
    width: 100%;
    max-height: 780px;
}

.titleWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 55%;
    height: 100%;
    max-height: 214px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.74) 28.14%, #FFFFFF 49.66%, rgba(255, 255, 255, 0.73) 73.92%, rgba(255, 255, 255, 0) 100%);
    box-shadow: 0px 2px 2px rgba(53, 67, 93, 0.25);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 36px;
    text-align: center;
}

.title {
    text-transform: uppercase;
    font-size: var(--font-size-XXXL);
    margin-bottom: 5px;
}

.button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateY(50%) translateX(-50%);
}

@media (max-width: 1100px) {
    .titleWrapper {
        width: 75%;
    }
}

@media (max-width: 768px) {
    .root::after {
        height: 9px;
        bottom: -9px;
        width: 85%;
    }

    .titleWrapper {
        max-height: 116px;
        padding-top: 14px;
        width: 100%;
    }

    .title {
        font-size: var(--font-size-L);
    }

    .paragraph {
        font-size: var(--font-size-S);
    }

    .button {
        width: 96px;
        height: 31px;
        font-size: var(--font-size-S);
        min-width: auto;
        bottom: -5px;
    }
}

