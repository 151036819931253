.root {
    width: 100%;
    max-width: 470px;
    border: 1px solid var(--gray-400);
    display: flex;
    flex-direction: column;
    padding: 55px 58px 67px;
    position: relative;
}

.label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    height: 43px;
    min-width: 210px;
    background-color: var(--dark-blue);
    color: var(--white);
    position: absolute;
    top: -22px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}

.number {
    position: absolute;
    top: 22px;
    left: -36px;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background-color: var(--gray-300);
    color: var(--grenade);
    display: grid;
    place-items: center;
    font-size: var(--font-size-XXXXL);
    font-weight: var(--weight-bold);
}

.pretitle {
    text-align: center;
    color: var(--gray-600);
}

.title {
    margin: 27px 0 34px;
    text-align: center;
    font-weight: var(--weight-semi-bold);
}

.description {
    margin: 38px 0;
}

.link {
    font-size: var(--font-size-S);
    display: flex;
    align-items: center;
    position: relative;
    width: fit-content;
}

.link svg {
    margin-left: 11px;
}

.link::after {
    display: block;
    content: '';
    position: absolute;
    bottom: -3px;
    width: 100%;
    height: 1px;
    background-color: #252A2F;
}

@media (max-width: 768px) {
    .root {
        padding: 45px 35px 25px;
    }

    .label {
        font-size: var(--font-size-XS);
        max-width: 120px;
        max-height: 30px;
        min-width: auto;
        padding: 0 10px;
    }

    .number {
        display: none;
    }

    .pretitle {
        font-size: var(--font-size-M);
    }

    .title {
        margin: 8px 0 10px;
    }

    .description {
        font-size: var(--font-size-M);
        margin: 11px 0 19px;
    }

    .link {
        font-size: var(--font-size-S);
    }
}