.root {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wrapper {
    width: 100%;
    max-width: var(--max-width);
    display: flex;
    justify-content: center;
}

.formsWrapper {
    composes: wrapper;

    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas: 
    "info image"
    "graphic image";
    padding-left: 120px;
}

.info {
    grid-area: info;
    position: relative;
    display: grid;
    row-gap: 25px;
    align-self: center;
    margin-bottom: 30px;
}

.info span {
    font-size: var(--font-size-M);
    color: var(--gray-600);
}

.info::before {
    display: block;
    content: '';
    position: absolute;
    width: 6px;
    top: 0;
    left: -120px;
    bottom: 0;
    background-color: var(--blue);
}

.graphic {
    grid-area: graphic;
}

.graphic img {
    max-width: 540px;
    width: 100%;
    height: auto;
}

.image {
    grid-area: image;
}

.image img {
    max-width: 608px;
    width: 100%;
    height: auto;
}

@media (max-width: 1460px) {
    .info::before {
        left: -100px;
    }
}

@media (max-width: 1250px) {
    .formsWrapper {
        padding-left: 60px;
    }

    .info::before {
        left: -40px;
    }
}

@media (max-width: 768px) {
    .formsWrapper {
        grid-template-columns: 100%;
        grid-template-areas: 
        "info"
        "graphic"
        "image";
        padding-left: 0;
        row-gap: 50px;
    }

    .info {
        padding: 0 30px 0 60px;
    }

    .info span,
    .info p {
        font-size: var(--font-size-S);
    }

    .info h2 {
        font-size: var(--font-size-L);
    }

    .info::before {
        left: 25px;
    }

    .graphic,
    .image {
        padding: 0 30px;
    }
}