.root {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
}

.wrapper {
    width: 100%;
    max-width: var(--max-width);
    display: flex;
    flex-direction: column;
    position: relative;
}

.wrapper::before {
    position: absolute;
    content: '';
    display: block;
    top: 100px;
    bottom: 0;
    left: 0;
    width: 6px;
    background-color: var(--blue);
}

.offerTitle {
    text-align: center;
    margin-bottom: 70px;
}

.offer {
    display: flex;
    justify-content: center;
}

.offer div:first-child {
    margin-right: 75px;
}

.aboutUs {
    display: grid;
    grid-template-columns: 1fr 2fr;
    place-items: center;
    padding-left: 120px;
}

.aboutUsInfo {
    display: grid;
    row-gap: 30px;
}

.pretitle {
    color: var(--gray-600);
}

.button {
    max-width: 183px;
}

.mockup {
    max-width: 911px;
    width: 100%;
    height: auto;
}

@media (max-width: 1440px) {
    .wrapper::before {
        display: none;
    }

    .aboutUs {
        padding-left: 50px;
        padding-right: 20px;
    }
}

@media (max-width: 1200px) {
    .root {
        margin-top: 60px;
    }

    .aboutUs {
        margin-bottom: 60px;
    }
}

@media (max-width: 1100px) {
    .offer {
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
    }

    .offer div:first-child {
        margin-right: 0;
        margin-bottom: 60px;
    }
}

@media (max-width: 1000px) {
    .aboutUs {
        grid-template-columns: 1fr 1fr;
    }

    .aboutUsInfo {
        margin-right: 30px;
    }
}

@media (max-width: 768px) {
    .offerTitle {
        text-align: left;
        padding: 0 40px;
        margin-bottom: 50px;
    }

    .offer {
        margin: 0 42px;
    }

    .aboutUsMockup {
        display: none;
    }

    .aboutUs {
        grid-template-columns: 1fr;
        padding: 0 40px;
    }

    .aboutUsInfo {
        margin-right: 0;
        margin-bottom: 30px;
    }

    .pretitle {
        font-size: var(--font-size-S);
    }

    .title {
        font-size: var(--font-size-L);
    }

    .description {
        font-size: var(--font-size-M);
    }

    .button {
        font-size: var(--font-size-S);
    }
}