.root {
    width: 100%;
    display: grid;
    place-items: center;
    margin: 140px 0;
}

.wrapper {
    max-width: var(--max-width);
    display: grid;
    grid-template-columns: repeat(4, auto);
    column-gap: 95px;
}

.wrapper img {
    image-rendering: pixelated;
}

.rootMobile {
    margin: 140px 0;
}

.rootMobile img {
    width: auto !important;
    height: 30px;
}

.rootMobile :global .slick-slide {
    display: flex;
    justify-content: center;
}

.titleArea {
    width: 100%;
    max-width: var(--max-width);
    padding-left: 120px;
    margin-bottom: 60px;
}

.title {
    font-size: var(--font-size-XXL);
    color: rgba(85, 94, 114, 0.6);
}

@media (max-width: 960px) {
    .wrapper {
        column-gap: 50px;
    }

    .titleArea {
        padding-left: 60px;
    }
}

@media (max-width: 830px) {
    .wrapper {
        grid-template-columns: repeat(2, auto);
        justify-items: center;
        row-gap: 45px;
        column-gap: 75px;
    }
}

@media (max-width: 768px) {
    .title {
        font-size: var(--font-size-XL);
    }
}

@media (max-width: 380px) {
    .rootMobile img {
        height: 40px;
    }

    .titleArea {
        text-align: center;
        padding-left: 0;
    }
}