.root {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 120px 0 180px;
}

.infoSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    position: relative;
    margin-bottom: 180px;
}

.infoSection::before {
    display: block;
    content: '';
    position: absolute;
    width: 6px;
    top: 0;
    bottom: 0;
    background-color: var(--blue);
}

.info {
    padding-left: 120px;
    max-width: 580px;
    display: grid;
    row-gap: 25px;
    align-content: center;
}

.info span {
    color: var(--gray-600);
}

.image {
    text-align: right;
    position: relative;
}

.image::before {
    display: block;
    content: '';
    position: absolute;
    height: 139px;
    width: 60vw;
    top: 50%;
    left: -50px;
    transform: translateY(-50%);
    background-color: var(--dark-blue);
    z-index: -1;
}

.materials {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    position: relative;
}

.labelMaterial {
    width: 100%;
    height: 270px;
    background-color: var(--blue);
    color: var(--white);
    display: grid;
    place-items: center;
    padding: 0 50px;
    text-align: center;
    position: relative;
}

.material {
    width: 100%;
    height: 270px;
    background-color: var(--white);
    padding: 45px 40px 0;
    border: 1px solid var(--gray-400);
    position: relative;
}

.material h4 {
    text-align: center;
    margin-bottom: 21px;
}

.labelMaterial::after,
.material::after {
    height: 3px;
    width: 100px;
    display: block;
    content: '';
    position: absolute;
    bottom: 27px;
    right: 51px;
}

.labelMaterial::after {
    background-color: var(--white);
}

.material::after {
    background-color: var(--gray-400);
}

.materials::before {
    display: block;
    content: '';
    position: absolute;
    left: -100%;
    top: 50%;
    transform: translateY(-50%);
    height: 164px;
    width: 200vw;
    background-color: var(--gray-200);
}

@media (max-width: 1460px) {
    .infoSection::before {
        left: 20px;
    }

    .materials {
        margin: 0 20px;
    }
}

@media (max-width: 1300px) {
    .image {
        padding: 0 25px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .image img {
        width: 90%;
    }

    .image::before {
        display: none;
    }
}

@media (max-width: 1250px) {
    .info {
        padding-left: 60px;
    }
}

@media (max-width: 1150px) {
    .labelMaterial,
    .material {
        height: 320px;
    }
}

@media (max-width: 950px) {
    .labelMaterial,
    .material {
        height: 470px;
    }
}

@media (max-width: 768px) {
    .root {
        margin: 95px 0 120px;
    }

    .root span {
        font-size: var(--font-size-S);
    }

    .root h2 {
        font-size: var(--font-size-L);
    }

    .root p {
        font-size: var(--font-size-S);
    }

    .infoSection {
        grid-template-columns: 1fr;
        justify-items: center;
        padding-right: 30px;
        margin-bottom: 90px;
    }

    .image {
        display: none;
    }

    .materials {
        grid-template-columns: 1fr;
        justify-items: center;
    }

    .labelMaterial,
    .material {
        max-width: 80%;
        height: auto;
    }

    .material {
        min-height: 210px;
        padding: 25px 20px 45px;
        text-align: center;
    }

    .material h4 {
        margin-bottom: 8px;
    }

    .labelMaterial {
        min-height: 170px;
    }
}