.root {
    position: fixed;
    top: 70px;
    left: 50%;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: calc(100% - 65px);
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    z-index: 10;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    transform: scale(1.15);
    transition-duration: 192ms;
    transition-timing-function: var(--anim-out);
    transition-property: opacity, transform, visibility;
}

.rootOpen {
    composes: root;

    opacity: 0.95;
    transform: scale(1) translateX(-50%);
    transition-duration: 224ms;
    transition-timing-function: var(--anim-in);
    visibility: visible;
    pointer-events: auto;
}

.navigation {
    width: 100%;
}

.navigation ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0;
    align-items: center;
}

.navigation ul li {
    padding: 17px 0;
    font-weight: var(--weight-semi-bold);
    width: 100%;
    height: 100%;
    text-align: center;
    position: relative;
}

.navigation a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
}

.navigation a svg {
    width: 22px;
    height: 22px;
    position: absolute;
    top: 50%;
    left: calc(50% + 32px);
    fill: var(--blue);
    transform: translateY(-50%);
}

.isActive a {
    color: var(--blue);
}

.haveSubmenu {
    background-color: rgba(99, 114, 146, 0.2);
}

.isOpen {
    padding-bottom: 0 !important;
}

.submenu {
    visibility: hidden;
    opacity: 0;
    display: none !important;
    transform: scaleY(0);
    margin-top: 17px;
}

.submenu li {
    background-color: rgba(37, 42, 47, 0.05);
}

.submenuOpen {
    composes: submenu;

    visibility: visible;
    opacity: 1;
    display: block !important;
    animation: growDown 300ms ease-in-out forwards;
    transform-origin: top center;
}

@keyframes growDown {
    0% {
        transform: scaleY(0)
    }
    80% {
        transform: scaleY(1.1)
    }
    100% {
        transform: scaleY(1)
    }
}