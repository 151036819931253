.root {
    width: 100%;
    height: 100px;
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
}

.navigation {
    height: 100%;
    display: flex;
    margin-right: 35px;
}

.menu {
    display: flex;
    align-items: center;
    height: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.menuItem {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 40px;
    font-weight: var(--weight-semi-bold);
    cursor: pointer;
}

.withChildren {
    background-color: rgba(53, 67, 93, 0.7);
    position: relative;
}

.withChildren:hover > .submenu {
    display: flex;
}

.withChildren > a {
    color: var(--white);
}

.menuItem a {
    position: relative;
}

.menuItem:hover > a {
    color: var(--blue);
}

.withChildren:hover > a {
    color: var(--white);
}

.menuItem > a::after {
    display: block;
    position: absolute;
    content: '';
    height: 1px;
    width: 100%;
    bottom: -3px;
    background-color: var(--blue);
    opacity: 0;
    overflow: hidden;
    transition: opacity 300ms, transform 300ms;
    transform: translate3d(-100%, 0, 0);
}

.withChildren > a::after {
    display: block;
    position: absolute;
    content: '';
    height: 1px;
    width: 100%;
    bottom: -3px;
    background-color: var(--white);
    opacity: 0;
    overflow: hidden;
    transition: opacity 300ms, transform 300ms;
    transform: translate3d(-100%, 0, 0);
}

.menuItem:hover > a::after {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}

.withChildren:hover > a::after {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}

.arrowDown {
    stroke: var(--white);
    fill: var(--white);
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.logo {
    display: flex;
    align-items: center;
    padding: 0 55px;
}

.submenu {
    display: none;
    flex-direction: column;
    position: absolute;
    bottom: -98px;
    right: 0;
    list-style-type: none;
}

.submenu li {
    width: 350px;
    height: 49px;
    background-color: var(--white);
    padding: 0 15px 0 28px;
    display: flex;
    align-items: center;
    z-index: 10;
}

.submenu li:first-child {
    border-bottom: 1px solid var(--grenade);
}

.submenu a {
    color: var(--text-color);
}

.submenu li:hover a {
    color: var(--blue);
}

.square {
    width: 8px;
    height: 8px;
    background-color: var(--dark-blue);
    margin-right: 17px;
}

@media (max-width: 1024px) {
    .navigation {
        margin-right: 0;
    }
}

@media (max-width: 1000px) {
    .root {
        justify-content: space-between;
        padding: 0 40px;
    }

    .logo {
        padding: 0;
    }
}

@media (max-width: 768px) {
    .root {
        height: 82px;
    }

    .logo img {
        width: 173px;
        height: auto;
    }
}