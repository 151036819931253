.root {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wrapper {
    width: 100%;
    max-width: var(--max-width);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 220px 0 350px;
    position: relative;
}

.wrapper::after,
.wrapper::before {
    display: block;
    position: absolute;
    content: '';
    width: 6px;
    bottom: -170px;
    top: -75px;
    background-color: var(--blue);
}

.wrapper::before {
    left: 0;
}

.wrapper::after {
    right: 0;
}

.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 583px;
    height: 583px;
    border-radius: 50%;
    border-top: 35px solid var(--dark-blue);
    border-left: 35px solid var(--dark-blue);
    border-bottom: 35px solid var(--light-blue);
    border-right: 35px solid var(--light-blue);
    position: relative;
}

.circle::before,
.circle::after {
    display: block;
    content: '';
    position: absolute;
    left: -35px;
    top: -35px;
    width: 583px;
    height: 583px;
    border-radius: 50%;
    border-right: 35px solid transparent;
    border-bottom: 35px solid transparent;
    border-left: 35px solid transparent;
}

.circle::before {
    border-top: 35px solid var(--blue);
    transform: rotate(60deg);
}

.circle::after {
    border-top: 35px solid var(--blue);
    transform: rotate(30deg);
}

.circle h2 {
    z-index: 2;
    text-transform: uppercase;
    margin-bottom: 25px;
}

.circle span {
    font-size: var(--font-size-M);
    color: var(--gray-600);
}

.cardAddress,
.cardContact,
.cardSales {
    position: absolute;
    border: 1px solid var(--gray-400);
    box-shadow: 0px 4px 4px rgba(101, 106, 111, 0.3);
    padding: 30px 40px;
    color: var(--text-color);
    background-color: var(--white);
    z-index: 2;
}

.cardAddress span,
.cardContact span,
.cardSales span {
    color: var(--text-color);
}

.bold {
    font-weight: var(--weight-semi-bold);
}

.cardAddress {
    left: -250px;
    top: 65px;
}

.cardContact {
    right: -150px;
    top: -100px;
}

.cardSales {
    bottom: -200px;
    right: 20px;
}

.cta {
    width: 100%;
    min-height: 330px;
    background: linear-gradient(0deg, rgba(42, 54, 79, 0.8), rgba(42, 54, 79, 0.8)),  url('../../assets/images/ctaBackground.jpg');
    color: var(--white);
    display: grid;
    align-content: center;
    justify-content: center;
    row-gap: 25px;
    text-align: center;
}

.cta p {
    max-width: 650px;
}

@media (max-width: 1480px) {
    .wrapper::before {
        left: 120px;
    }

    .wrapper::after {
        right: 120px;
    }
}

@media (max-width: 1300px) {
    .wrapper::after,
    .wrapper::before {
        display: none;
    }

    .cardAddress {
        left: -240px;
    }
}

@media (max-width: 1100px) {
    .circle {
        margin-left: 40px;
    }
}

@media (max-width: 1000px) {
    .wrapper {
        margin: 95px 0 120px;
    }

    .wrapper::before {
        display: block;
        left: 35px;
        top: 0;
        bottom: 0;
    }

    .circle {
        border: none;
        position: static;
        height: auto;
        width: 100%;
    }

    .circle h2 {
        margin-bottom: 13px;
    }

    .circle::before,
    .circle::after {
        display: none;
    }

    .cardAddress,
    .cardContact,
    .cardSales {
        position: static;
        width: 70%;
        text-align: center;
        margin-bottom: 60px;
    }

    .cardAddress {
        margin-top: 45px;
    }

    .cardSales {
        margin-bottom: 0;
    }
}

@media (max-width: 768px) {
    .circle h2,
    .circle h3 {
        font-size: var(--font-size-L);
    }

    .circle span {
        font-size: var(--font-size-S);
    }

    .circle {
        margin-left: 0;
    }

    .cta {
        font-size: var(--font-size-S);
        row-gap: 10px;
        padding: 0 50px;
    }

    .cta h2 {
        font-size: var(--font-size-L);
        margin-bottom: 6px;
    }
}

@media (max-width: 500px) {
    .cardAddress,
    .cardContact, 
    .cardSales {
        width: 80%;
    }

    .wrapper {
        padding-left: 25px;
    }
}