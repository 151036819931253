.root {
    width: 100%;
    height: 100%;
    position: relative;
}

.root :global .slick-dots {
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
}

.root :global .slick-dots li button::before {
    color: rgba(241, 243, 245, 0.6);
    opacity: 1 !important;
    font-size: 10px;
}

.root :global .slick-dots li.slick-active button::before {
    color: var(--white);
}

@media (max-width: 950px) {
    .root {
        padding-top: 50px;
    }
}

@media (max-width: 768px) {
    .root :global .slick-dots li button::before {
        color: var(--gray-400);
    }

    .root :global .slick-dots li.slick-active button::before {
        color: var(--black);
    }
}